<template>
  <div class="wameed-dashboard-page-content">
  
  </div>
</template>

<script>
 
export default {
  components: {
    
  },
  data() {
    return {
      
    };
  },

  methods: {
    
  },
};
</script>
 
